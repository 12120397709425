import * as Sentry from '@sentry/browser';
import { FC, memo, useEffect, useState, useCallback } from 'react';
import { client } from '~/app/api';
import ChevronDownSVG from '~/app/images/chevron-down.svg';
import { useDispatch } from 'react-redux';
import spinnerGif from '~/app/images/spinner.gif';
import { Icon, IconButton, StaticTooltip, Button } from '../../../reuse';
import { useTemporarilyTrueBoolean } from '../../../utils/useTemporarilyTrueBoolean';
import { copyTextToClipboard } from '../../../utils/clipboard/copyTextToClipboard';
import {
  addToNoteStyle,
  betaChipStyle,
  copiedBackgroundStyle,
  copiedMessageIconStyle,
  copiedMessageStyle,
  copiedPillStyle,
  copiedStyle,
  oneLinerCaretButtonStyle,
  oneLinerCaretSyleClosed,
  oneLinerCaretSyleOpen,
  oneLinerHeaderStyle,
  oneLinerTaglineStyle,
  oneLinerTextStyleClosed,
  oneLinerTextStyleOpen,
  oneLinerTitleStyle,
  spacerStyle,
  css,
  tooltipStyle,
  titleContainerStyle,
  loadingAndErrorStyle,
  loadingAndErrorConentStyle,
  loadingSpinnerStyle,
  loadingTextStyle,
  errorTextStyle1,
  errorTextStyle2,
} from './styles';

export const OneLiner: FC = memo(() => {
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [oneLiner, setOneLiner] = useState('');

  const [isCollapsed, setIsCollapsed] = useState(false);

  const [copied, { setTemporarilyTrue }] = useTemporarilyTrueBoolean(2000);

  const dispatch = useDispatch();
  const handleAddToNote = useCallback(() => {
    setIsCollapsed(true);
    dispatch({
      type: 'modify note block',
      payload: {
        oneLinerText: oneLiner,
        type: 'addOneLiner',
      },
    });
  }, [dispatch, oneLiner]);

  const handleToggleCollapse = () => {
    setIsCollapsed(!isCollapsed);
  };

  const handleCopyClick = async () => {
    copyTextToClipboard(oneLiner);
    setTemporarilyTrue();
  };

  useEffect(() => {
    async function fetchData() {
      try {
        const pId = window.regardEncodedPatientId;
        const encId = window.regardEncounterId;

        // Throw an error if either patientId or encounterId is undefined
        if (!pId) {
          throw new Error('Patient ID is missing.');
        }
        if (!encId) {
          throw new Error('Encounter ID is missing.');
        }
        const { data, error } = await client.GET('/openapi/oneLiner', {
          params: {
            query: {
              patientId: pId,
              encounterId: encId,
            },
          },
          headers: {
            // Make sure the check is not cached. With no headers, IE tries
            // to be "helpful" and caches the initial response.
            // Cache-Control, Pragma, and Expires are necessary
            'Cache-Control': 'no-cache, no-store, must-revalidate',
            Pragma: 'no-cache',
            Expires: '-1',
          },
        });
        if (error) {
          throw new Error('An error occurred while fetching data from API.');
        } else {
          setOneLiner(data.oneLiner);
        }
      } catch (error) {
        setIsError(true);
        Sentry.withScope((scope) => {
          scope.setExtra('error', error);
          Sentry.captureException('Error getting last AI response.');
        });
      } finally {
        setIsLoading(false);
      }
    }

    fetchData();
  }, []);

  return (
    <div css={css} data-cy-one-liner>
      <button
        aria-label={isCollapsed ? 'expand' : 'collapse'}
        onClick={handleToggleCollapse}
        style={oneLinerCaretButtonStyle}
        type="button"
      >
        <ChevronDownSVG style={isCollapsed ? oneLinerCaretSyleClosed : oneLinerCaretSyleOpen} />
      </button>
      <div style={oneLinerHeaderStyle}>
        <span style={betaChipStyle}>BETA</span>
        <span style={titleContainerStyle}>
          <span style={oneLinerTitleStyle}>One-Liner</span>
          <span style={oneLinerTaglineStyle}>generated by Regard AI</span>
        </span>
        <div style={spacerStyle} />
        <Button
          color="tertiary"
          data-cy-one-liner-add-to-note
          disabled={isLoading || isError}
          onClick={handleAddToNote}
          size="mini"
          style={addToNoteStyle}
        >
          Add To Note
        </Button>
        <StaticTooltip placement="left" style={tooltipStyle} tooltip="Copy One-Liner">
          <IconButton
            color="tertiary-blue"
            data-cy-one-liner-copy
            disabled={isLoading || isError}
            iconName="copy"
            onClick={handleCopyClick}
            size="mini"
          />
        </StaticTooltip>
      </div>
      {copied && (
        <div style={copiedStyle}>
          <div style={copiedBackgroundStyle} />
          <div style={copiedPillStyle}>
            <div style={copiedMessageStyle}>
              <Icon iconName="fileCheckNew" style={copiedMessageIconStyle} />
              <span>Copied to clipboard</span>
            </div>
          </div>
        </div>
      )}
      {isLoading && (
        <div data-cy-one-liner-loading style={loadingAndErrorStyle}>
          <div style={loadingAndErrorConentStyle}>
            <img alt="spinner" src={spinnerGif} style={loadingSpinnerStyle} />
            <div style={loadingTextStyle}>Generating One-Liner...</div>
          </div>
        </div>
      )}
      {isError && (
        <div data-cy-one-liner-error style={loadingAndErrorStyle}>
          <div style={loadingAndErrorConentStyle}>
            <div style={errorTextStyle1}>Failed to generate one-liner.</div>
            <div style={errorTextStyle2}>We are working on this issue.</div>
          </div>
        </div>
      )}
      {!isLoading && !isError && (
        <div
          data-cy-one-liner-text
          style={isCollapsed ? oneLinerTextStyleClosed : oneLinerTextStyleOpen}
        >
          {oneLiner}
        </div>
      )}
    </div>
  );
});
OneLiner.displayName = 'OneLiner';
