import _ from 'lodash';

import {
  APIBullet,
  ConditionAssessmentPlan,
  SimpleCondition,
  DiffLabel,
  Bullet,
  HistoricalBulletData,
  HistoricalCondition,
} from '~/app/@types/state';
import { InvalidBulletID, InvalidBulletText } from '~/app/errors';
import { captureExceptionWithContext } from '~/app/analytics';
import { BulletType } from '~/app/@types';
import { get, convertBulletTextToBulletKey } from '~/app/utils';
import { NEW_DIFF, EXISTING_DIFF, UPDATED_DIFF, OUTDATED_DIFF } from '~/app/constants';
import { stringifyItems } from '../stringifyItems';
import { safetyCheckBullets, SafetyBulletTracker } from './safetyChecks';

type BulletProcessingProps = { bulletText: string; bulletData: APIBullet; bulletType: BulletType };

const currentComplaintToBulletDataMaps = ({
  assessment,
  plan,
  general,
}: ConditionAssessmentPlan) => {
  const bulletSectionPairs: [BulletType, APIBullet[]][] = [
    [BulletType.Assessment, assessment],
    [BulletType.Plan, plan],
    [BulletType.General, general],
  ];
  const allHistoricalAPIBullets = _.flatten(
    bulletSectionPairs.map(([bulletType, sectionBullets]) =>
      sectionBullets.map<BulletProcessingProps>((sectionBullet) => ({
        bulletType,
        bulletData: sectionBullet,
        bulletText: stringifyItems(sectionBullet.item),
      }))
    )
  );
  return allHistoricalAPIBullets;
};

// Some bullet IDs appear on the previous condition results but NOT on the new props
// These bullets are marked with a diff "removed" unless it is a medication
// bullet, then it is added to a lookup map to remove its text from the meshed note
const getOutdatedAndRemovableMedBullets = (
  notUpdatedPreviousRegardConditionBullets: Record<string, HistoricalBulletData>
) => {
  // This map will be used by meshing code to detect and remove
  // unwanted/unprescribed medication bullet lines
  const removableMedBulletText: string[] = [];

  // This map will be used to track out-of-date Regard bullets that have no
  // corresponding updated text in current props
  const outdatedBullets: Bullet[] = [];

  Object.entries(notUpdatedPreviousRegardConditionBullets).forEach(([id, bullet]) => {
    // We only want to track previous Regard bullets if:
    // 1. It has not been updated (we filter those out before this function)
    // 2. It is not a GENERAL bullet (we filter those out before this function)
    // 3. If is not a medication bullet (it has likely been discontinued if it
    //    is not found in today's props)

    const previousTextVariations = Object.keys(bullet.textVariationsToTimestamps);

    if (bullet.isMed) {
      previousTextVariations.forEach((previousTextVariation) => {
        // Here we map all variations of outdated med bullets into an array
        // this is later used in analyzeAndMeshChunks.attemptToMatchRegardBulletTextInLines
        // to remove Regard med bullets from the basenote
        removableMedBulletText.push(previousTextVariation);
      });
    } else {
      // for bullets that pass the 3 rules above, we make a seperate record for
      // each possible historical text match so we can track if found in editor
      previousTextVariations.forEach((textVariation) => {
        outdatedBullets.push({
          diff: OUTDATED_DIFF,
          id,
          tags: bullet.tags,
          text: textVariation,
          type: BulletType.General, // General to ensure this bullet is never suggested
        });
      });
    }
  });

  return {
    removableMedBulletText,
    outdatedBullets,
  };
};

// Using bullet Ids to match, we compare the content of the previous and current bullets
// If the text is the same, the current bullet has a diff of "existing",
// If the text is different, the current bullet has a diff of "updated",
// If there is not a matching bullet ID in the previous props, the diff is "new"
export const stringifyAndDiffBullets = ({
  historicalConditionData,
  isBeforeBasenoteEffective,
  allBullets,
}: {
  historicalConditionData: HistoricalCondition | undefined;
  isBeforeBasenoteEffective: (timestamp: ISODateString) => boolean;
  allBullets: BulletProcessingProps[];
}): {
  staleToUpdatedBulletTextMap: Record<
    string, // old bullet text
    string // new bullet text
  >;
  removableMedBulletText: string[];
  outdatedBullets: Bullet[];
  diffedBullets: Bullet[];
} => {
  // The following object stores key-value pairs of updatable bullet text
  // ie { "most recent serum creatinine 1.9 mg/dL 8/1/21": "most recent serum creatinine 1.7 mg/dL 8/2/21" ...  }
  const staleToUpdatedBulletTextMap: Record<
    string, // old bullet text
    string // new bullet text
  > = {};

  const noMatchForTheseHistoricalBulletIds = new Set<string>();
  const historicalBulletTextVariationsToBulletIds: Record<string, Set<string>> = {};

  const observationKeyHistoricalBulletId: Record<string, Set<string>> = {};

  if (historicalConditionData?.bulletIdToBulletDataMap) {
    Object.entries(historicalConditionData?.bulletIdToBulletDataMap).forEach(
      ([bulletId, historicalBulletData]) => {
        noMatchForTheseHistoricalBulletIds.add(bulletId);

        Object.keys(historicalBulletData.textVariationsToTimestamps).forEach((textVariation) => {
          if (!historicalBulletTextVariationsToBulletIds[textVariation])
            historicalBulletTextVariationsToBulletIds[textVariation] = new Set<string>();
          historicalBulletTextVariationsToBulletIds[textVariation].add(bulletId);
        });

        if ('observationKey' in historicalBulletData && historicalBulletData.observationKey) {
          if (!observationKeyHistoricalBulletId[historicalBulletData.observationKey])
            observationKeyHistoricalBulletId[historicalBulletData.observationKey] =
              new Set<string>();
          observationKeyHistoricalBulletId[historicalBulletData.observationKey].add(bulletId);
        }
      }
    );
  }

  const diffedBullets = allBullets.map<Bullet>(({ bulletType, bulletData, bulletText }) => {
    let diff: DiffLabel = NEW_DIFF;

    if (historicalConditionData) {
      const { bulletIdToBulletDataMap, observationKeyToBulletDataMap } = historicalConditionData;
      const previousTextVariationsForBulletId =
        get(bulletIdToBulletDataMap, bulletData.id)?.textVariationsToTimestamps ?? {};

      // Here we filter out textVariations that already match the current bullet's text,
      // (since there is no point in "updating" a line to the exact same text..)
      // This builds our initial "update" map
      Object.keys(previousTextVariationsForBulletId)
        .filter((priorTextVariation) => priorTextVariation !== bulletText)
        .forEach((priorTextVariation) => {
          staleToUpdatedBulletTextMap[priorTextVariation] = bulletText;
        });

      // Some evidence bullets are "combo" bullets and contain multiple IDs connected by "&"
      // We need to also add the sub-bullet-id mappings to our object so we could update an
      // outdated obesity.bmi-last with a newer obesity.bmi-last&bmi-details
      const splitBulletIds = bulletData.id.split('&');
      const otherTextVariationsToConsider =
        splitBulletIds.length > 1
          ? splitBulletIds.map(
              (id) => get(bulletIdToBulletDataMap, id)?.textVariationsToTimestamps ?? {}
            )
          : [];

      if ('observationKey' in bulletData && bulletData.observationKey) {
        otherTextVariationsToConsider.push(
          get(observationKeyToBulletDataMap, bulletData.observationKey)
            ?.textVariationsToTimestamps ?? {}
        );
      }

      const otherPreviousTextVariations: Record<string, ISODateString[]> = {};
      otherTextVariationsToConsider.forEach((textVariationsToTimestamps) => {
        Object.entries(textVariationsToTimestamps).forEach(([textVariation, timestamps]) => {
          if (otherPreviousTextVariations[textVariation])
            otherPreviousTextVariations[textVariation].push(...timestamps);
          else otherPreviousTextVariations[textVariation] = [...timestamps];
        });
      });

      // Same as above but we prioritize any update mappings already made (this means
      // updates by exact bulletID match take priority) and we fill the "holes" with
      // combo bullet updates and/or observationKey updates
      Object.keys(otherPreviousTextVariations)
        .filter((priorTextVariation) => priorTextVariation !== bulletText)
        .forEach((priorTextVariation) => {
          if (!staleToUpdatedBulletTextMap[priorTextVariation]) {
            staleToUpdatedBulletTextMap[priorTextVariation] = bulletText;
          }
        });

      const previousTextVariations = { ...previousTextVariationsForBulletId };
      Object.entries(otherPreviousTextVariations).forEach(([textVariation, timestamps]) => {
        if (previousTextVariations[textVariation])
          previousTextVariations[textVariation].push(...timestamps);
        else previousTextVariations[textVariation] = [...timestamps];
      });

      if (
        previousTextVariations[bulletText] &&
        previousTextVariations[bulletText].some(isBeforeBasenoteEffective)
      ) {
        diff = EXISTING_DIFF;
      } else {
        const hasHistoricalIdMatch = get(bulletIdToBulletDataMap, bulletData.id);
        const hasHistoricalObsKeyMatch =
          'observationKey' in bulletData &&
          get(observationKeyToBulletDataMap, bulletData.observationKey);
        diff = hasHistoricalIdMatch || hasHistoricalObsKeyMatch ? UPDATED_DIFF : NEW_DIFF;
      }

      // Now that we have processed this bullet.id we can remove it from our trackers
      // These trackers are used later to determine outdated & medication bullets to remove from the basenote
      const matchedHistoricalBulletIds = new Set<string>();
      matchedHistoricalBulletIds.add(bulletData.id);

      // The are two bullets in this historical data set that have IDs similar such as:
      // "levothyroxine_202111120159210000_2021121719250000_890mcg_po_daily_levothyroxine"
      // "levothyroxine_202111120159210000_2021121314000000_890mcg_po_daily_levothyroxine"
      //             Notice the discrepancy here: ^^^^^
      // One of the IDs will be removed from `noMatchForTheseHistoricalBulletIds` but the other does not.
      // Later on, the system will remove a `levothyroxine` bullet from the basenote because the other almost-
      //  identical ID corresponds to identical bullet text.

      // Here we attempt to find bullets with other IDs that have identical text. Then we can remove them
      // and prevent the system from later removing med lines from the basenote that should not be removed
      Object.keys(previousTextVariations).forEach((textVariation) => {
        const otherIds = get(historicalBulletTextVariationsToBulletIds, textVariation);
        if (otherIds) otherIds.forEach((id) => matchedHistoricalBulletIds.add(id));
      });

      if ('observationKey' in bulletData)
        get(observationKeyHistoricalBulletId, bulletData.observationKey)?.forEach((obsBulletId) => {
          matchedHistoricalBulletIds.add(obsBulletId);
        });

      matchedHistoricalBulletIds.forEach((id) => noMatchForTheseHistoricalBulletIds.delete(id));
    }

    const bulletMetaData = _.omit(bulletData, ['item']);
    return {
      diff,
      text: bulletText,
      type: bulletType,
      ...bulletMetaData,
    };
  });

  const unmatchedHistoricalBulletIdsToBulletDataMap =
    historicalConditionData?.bulletIdToBulletDataMap
      ? _.pick(
          historicalConditionData.bulletIdToBulletDataMap,
          ...Array.from(noMatchForTheseHistoricalBulletIds)
        )
      : {};

  const { removableMedBulletText, outdatedBullets } = getOutdatedAndRemovableMedBullets(
    unmatchedHistoricalBulletIdsToBulletDataMap
  );

  return {
    staleToUpdatedBulletTextMap,
    removableMedBulletText,
    outdatedBullets,
    diffedBullets,
  };
};

// Here we grab the qualifier text suggested in the Regard title
// This is used by the specChecker tooltip to suggest the qualifier
// if a similar one is not detected with regex
const getSuggestedQualifier = (
  currentRegardConditionTitle: string,
  conditionNameToQualifierRegex: Record<string, RegExp>
) => {
  // look for qualifiers in title
  const qualifierRegexes = Object.values(conditionNameToQualifierRegex);

  let suggestedQualifier = '';
  for (let i = 0; i < qualifierRegexes.length; i++) {
    const match = currentRegardConditionTitle.match(qualifierRegexes[i]);
    if (match) {
      // Here we pick out the qualifier portion of the title
      // if the title contains "history of" we want to suggest the whole title,
      // but if not, we just want to slice from the first qualifier to the end
      const qualifierText = currentRegardConditionTitle.match(/history of/gi)
        ? currentRegardConditionTitle
        : currentRegardConditionTitle.slice(match.index);
      if (suggestedQualifier.length < qualifierText.length) {
        suggestedQualifier = qualifierText;
      }
    }
  }

  return suggestedQualifier;
};

export const generateCurrentRegardConditionsMap = ({
  currentConditionAssessmentPlans,
  previousRegardConditionsMap,
  conditionQualifiers,
  conditionNameToPreviousTitleMap,
  isBeforeBasenoteEffective,
}: {
  currentConditionAssessmentPlans: ConditionAssessmentPlan[];
  previousRegardConditionsMap: Record<string, HistoricalCondition>;
  conditionQualifiers: Record<string, Record<string, RegExp>>;
  conditionNameToPreviousTitleMap: Record<string, string>;
  isBeforeBasenoteEffective: (timestamp: ISODateString) => boolean;
}) => {
  const regardGeneratedTitleLookup: Record<string, string> = {};
  const previousTitleToNewTitle: Record<string, string> = {};
  const updatableBulletTextByConditionName: Record<string, Record<string, string>> = {};
  const removableBulletTextKeyToConditionNameMap: Record<string, string[]> = {};
  const blankIdBullets: SafetyBulletTracker[] = [];
  const duplicateBullets: SafetyBulletTracker[] = [];
  const badWhitespaceBullets: SafetyBulletTracker[] = [];

  const currentRegardConditions = currentConditionAssessmentPlans.map((conditionAssessmentPlan) => {
    const conditionName = conditionAssessmentPlan.module;
    const historicalConditionData: HistoricalCondition | undefined =
      previousRegardConditionsMap[conditionName];
    const stringifiedTitle = stringifyItems(conditionAssessmentPlan.title);
    regardGeneratedTitleLookup[stringifiedTitle] = conditionName;

    const { diffedBullets, removableMedBulletText, outdatedBullets, staleToUpdatedBulletTextMap } =
      stringifyAndDiffBullets({
        historicalConditionData,
        isBeforeBasenoteEffective,
        allBullets: currentComplaintToBulletDataMaps(conditionAssessmentPlan),
      });

    const currentRegardCondition: SimpleCondition = {
      module: conditionName,
      negative: conditionAssessmentPlan.negative,
      title: stringifiedTitle,
      bullets: diffedBullets,
      diff: historicalConditionData ? EXISTING_DIFF : NEW_DIFF,
      suggestedQualifier: conditionQualifiers[conditionName]
        ? getSuggestedQualifier(stringifiedTitle, conditionQualifiers[conditionName])
        : '',
    };

    // Add to previous title lookup and collect bullets to be removed
    // if the title for this Regard condition has changed, ie "AKI: severe" => "AKI: improving"
    // we want to store the title update in the `previousTitleToNewTitle` map
    // To prevent writing over the previous title with the live-dx'd title we exclude negative conditions here
    if (
      historicalConditionData &&
      conditionNameToPreviousTitleMap[conditionName] !== stringifiedTitle &&
      !conditionAssessmentPlan.negative
    ) {
      previousTitleToNewTitle[conditionNameToPreviousTitleMap[conditionName]] = stringifiedTitle;
    }

    // Add deprecated bullets so they will show as tracked in UI if found in the baseNote
    currentRegardCondition.bullets.push(...outdatedBullets);

    // med bullet keys are added to this list so the meshNotes function
    // will try to match them and remove them from the final meshed Note
    removableMedBulletText.forEach((removableMedText) => {
      const key = convertBulletTextToBulletKey(removableMedText);

      if (removableBulletTextKeyToConditionNameMap[key]) {
        removableBulletTextKeyToConditionNameMap[key].push(conditionName);
      } else {
        removableBulletTextKeyToConditionNameMap[key] = [conditionName];
      }
    });

    // Check bullets for duplicate data or bad whitespace formatting

    const badBulletMaps = safetyCheckBullets(currentRegardCondition.bullets, conditionName);
    blankIdBullets.push(...badBulletMaps.blankIdBullets);
    duplicateBullets.push(...badBulletMaps.duplicateBullets);
    badWhitespaceBullets.push(...badBulletMaps.badWhitespaceBullets);

    updatableBulletTextByConditionName[conditionName] = staleToUpdatedBulletTextMap;

    return currentRegardCondition;
  });

  // Report bad bullets to sentry
  if (blankIdBullets.length) {
    captureExceptionWithContext(new InvalidBulletID('Blank IDs'), { blankIdBullets });
  }
  if (duplicateBullets.length) {
    captureExceptionWithContext(new InvalidBulletID('Duplicate IDs'), { duplicateBullets });
  }
  if (badWhitespaceBullets.length) {
    captureExceptionWithContext(new InvalidBulletText('2 or more spaces found in bullet text'), {
      badWhitespaceBullets,
    });
  }

  return {
    currentRegardConditions,
    currentRegardConditionsMap: _.keyBy(currentRegardConditions, 'module'),
    regardGeneratedTitleLookup,
    previousTitleToNewTitle,
    updatableBulletTextByConditionName, // key: module; value: (key: bullet text from yesterday, value: bullet text from today)
    removableBulletTextKeyToConditionNameMap,
    conditionNameToICDCodesMap: currentConditionAssessmentPlans.reduce(
      (map: Record<string, string[]>, c) => {
        // eslint-disable-next-line no-param-reassign
        map[c.module] = _.flatten(Object.values(c.codes));
        return map;
      },
      {}
    ),
  };
};
