import { ObservationInterpretation } from '~/app/@types';
import { get, convertBulletTextToBulletKey, nth } from '~/app/utils';
import {
  BulletLine,
  FreetextLine,
  Line,
  PostModuleNoteBlock,
  ShelfDividerLine,
  TitleLine,
} from '~/app/@types/state';

import { diffToStatus } from '../diffToStatus';

type TransformLinesParams = Pick<
  PostModuleNoteBlock,
  'bulletsByTrimmedTextKey' | 'diff' | 'lines' | 'modules'
>;

type TransformLinesReturn = {
  foundBulletIds: string[];
  transformedLines: Line[];
};

export const transformLines = (params: TransformLinesParams): TransformLinesReturn => {
  const { bulletsByTrimmedTextKey, diff, lines, modules } = params;

  const titleStatus = diffToStatus(diff);
  const firstTitleLineIndex = lines.findIndex((line) => line.type === 'title');
  let freetextLineCount = 0;
  const foundBulletIds: string[] = [];
  const bulletKeyByNumbeOfTimesUsed: Record<string, number> = {};

  const transformedLines = lines.map((line, index) => {
    // 1. Process any possible "pre-title" freetext lines (but only if a title exists)
    const isBeforeFirstTitleLine = index < firstTitleLineIndex;
    if (isBeforeFirstTitleLine && line.type === 'freetext') {
      const pretextLine: FreetextLine = {
        type: 'freetext',
        html: line.html,
        // We can only index here because pretext lines are first
        key: `pretext-${index}`,
        status: 'none',
      };
      return pretextLine;
    }

    // 2. Now process title lines
    if (line.type === 'title') {
      const titleIndex = index - firstTitleLineIndex;
      const titleLine: TitleLine = {
        ...line,
        // Add condition names to the first title only
        // NOTE: Ideally we would add the corresponding modules to each title
        //  line, but this is just how it's done for now
        conditionNames: titleIndex === 0 ? modules : undefined,
        // NOTE: This key is an approximation; it won't be perfect if, for
        //  example, we had two modules on a single title line
        key: `title-${nth(modules, titleIndex) ?? titleIndex}`,
        status: titleStatus,
      };

      return titleLine;
    }

    // 3. Now process bullet lines
    if (line.type === 'bullet') {
      // 3a. See if this typed bullet matches a regard bullet corresponding
      //  to the condition area's modules
      const regardBullet = get(
        bulletsByTrimmedTextKey,
        convertBulletTextToBulletKey(line.plainTextWithoutBulletSignifier)
      );

      if (regardBullet) {
        const { diff, ids } = regardBullet;

        // 3b. Bullet keys are formed by combining bullet ids
        const bulletKey = ids.join('-');
        if (bulletKey) {
          // 3c. Match duplicate bullets but append an index to the key, if
          //  duplicate
          const numberOfTimesUsed: number = (get(bulletKeyByNumbeOfTimesUsed, bulletKey) ?? 0) + 1;
          const key = `bullet-${bulletKey}${numberOfTimesUsed > 1 ? `-${numberOfTimesUsed}` : ''}`;
          bulletKeyByNumbeOfTimesUsed[bulletKey] = numberOfTimesUsed;

          // 3d. If there is a match, we have a "bullet", and we record this
          //  bullet as found for later processing
          ids.forEach((id) => foundBulletIds.push(id));

          const bulletLine: BulletLine = {
            ...line,
            ids,
            key,
            status: diffToStatus(diff),
            interpretation:
              'interpretation' in regardBullet
                ? regardBullet.interpretation
                : ObservationInterpretation.Unknown,
          };

          return bulletLine;
        }
      }

      // 3e. Otherwise, what we really have is a freetext line, and the case
      //  [5] below can cover this
    }

    // 4. Process shelf divider lines
    if (line.type === 'shelfDivider') {
      const pretextLine: ShelfDividerLine = {
        type: 'shelfDivider',
        html: line.html,
        htmlWithoutShelfSignifiers: line.htmlWithoutShelfSignifiers,
        key: `shelf-divider-${index}`,
        status: 'none',
      };
      return pretextLine;
    }

    // 5. Finally process freetext lines
    const freetextLine: FreetextLine = {
      html: line.html,
      key: `freetext-${freetextLineCount}`,
      status: 'none',
      type: 'freetext',
    };
    freetextLineCount += 1;

    return freetextLine;
  });

  return {
    foundBulletIds,
    transformedLines,
  };
};
