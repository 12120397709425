import { HtmlVersion } from '../@types';
import { getLocalStorageItem } from '../utils/webStorage';
import { isFlagEnabledForUser } from './isFlagEnabled';
import { getFlagValue } from './getFlagValue';
import { isDev } from './getEnv';
import './types';

/**
 * Whether the application allows querying.
 */
export const isQueryEnabled = () => isFlagEnabledForUser('QUERY_ENABLED', { defaultValue: isDev });

/**
 * Whether the application allows access to the PMEMR (Poor Man's Electronic Medical Record).
 */
export const isPMEMREnabled = () => isFlagEnabledForUser('PMEMR_ENABLED', { defaultValue: isDev });

export const isReview = () => new URLSearchParams(window.location.search).get('review') !== null;

// Enable this in frontend.dev.config.js to modify the UI to be the sales demo
export const isSalesDemoMode = isFlagEnabledForUser('SALES_DEMO_MODE');

// Cypress

export const isCypress = !!window.Cypress;
export const cyCachePatient: 'true' | '' =
  isCypress && getLocalStorageItem('cy-cache-patient') === 'true' ? 'true' : '';
export const cyCacheKey: string = (isCypress && getLocalStorageItem('cy-patient-id')) || '';

// Dev Tools

export const isDevToolsEnabled = !isCypress && !!window.REGARD_DEV_TOOLS_ENABLED;
export const isDevCachePatient =
  isDevToolsEnabled && getLocalStorageItem('regard-dev-cache-patient-checked') === 'true';

// disable refresh button
export const isRefreshButtonDisabled = isFlagEnabledForUser('REFRESH_BUTTON_DISABLED');

export const isDebugPerformance = isFlagEnabledForUser('DEBUG_PERFORMANCE');

export const getCurrentHtmlVersion = () => HtmlVersion.HtmlWithEMU;

export const useNumbersInsteadOfHashtagsWhenAppendingDxes = isFlagEnabledForUser(
  'USE_NUMBERS_WHEN_APPENDING_REGARD_DXES'
);

export const htmlUseBrLineFormatOnCopy = isFlagEnabledForUser('HTML_USE_BR_LINE_FORMAT_ON_COPY');
export const htmlWrapWithFontOnCopy = isFlagEnabledForUser('HTML_WRAP_WITH_FONT_ON_COPY');
export const htmlFontFaceOnCopy = getFlagValue('HTML_FONT_FACE_ON_COPY') ?? 'Arial, sans-serif';
export const htmlFontSizeOnCopy = getFlagValue('HTML_FONT_SIZE_ON_COPY') ?? '3';

export const htmlConvertLinesToListOnCopy = isFlagEnabledForUser(
  'HTML_CONVERT_LINES_TO_LISTS_ON_COPY'
);

export const secretCopyOverride = getFlagValue('HTML_SECRET_COPY_OVERRIDE') as HtmlString;

export const rteSyncInterval = () =>
  getFlagValue('RTE_SYNC_INTERVAL', { defaultValue: undefined }) ??
  getFlagValue('frontend_rte_sync_interval', { defaultValue: 2500 });

export const toolbarSyncInterval = getFlagValue('TOOLBAR_SYNC_INTERVAL', { defaultValue: 500 });

export const typingThrottleTimeout = getFlagValue('TYPING_THROTTLE_TIMEOUT', { defaultValue: 600 });

export const clipboardClearTimeout = getFlagValue('CLIPBOARD_CLEAR_TIMEOUT_MS');

export const getIsNewLoadingScreenEnabled = () =>
  !!isFlagEnabledForUser('frontend_new_loading_screen');

export const getIsNotesAppEnabled = () =>
  !!isFlagEnabledForUser('frontend_notes_app_enabled', {
    enableForAllInternalUsers: true,
  });

// TODO: RND-1407 - Turn back on appcues
// export const getIsAppcuesEnabled = () => !!isFlagEnabledForUser('frontend_appcues_enabled');
// export const getSendUfOnAppcuesFail = () => !!getFlagValue('frontend_send_uf_on_appcues_fail');

// In production, the notes app is served on its own route.
// In development, it's assumed the notes app is running on its own dev server.
export const getBaseNotesAppUrl = () => getFlagValue('frontend_notes_app_url') ?? 'notes/doc';
export const getNotesAppUrl = () => {
  const newBaseUrl = getBaseNotesAppUrl();
  const currentQueryParams = window.location.search;
  const newUrl = `${newBaseUrl}${currentQueryParams}`;
  return newUrl;
};

export const getRedirectToNotesApp = () => !!isFlagEnabledForUser('frontend_redirect_to_notes_app');

// ///////////
// AI Chat //
// ///////////

export const isAiChatEnabled = !!getFlagValue('AI_CHAT_ENABLED');

export const regardDisabledMessage = getFlagValue('REGARD_DISABLED_MESSAGE');
export const regardDisabledMessageSecondary = getFlagValue('REGARD_DISABLED_MESSAGE_SECONDARY');

// For testing only
export const isDraftLoadDisabled = !!getFlagValue('DRAFT_LOAD_DISABLED');
export const isDraftSaveDisabled = !!getFlagValue('DRAFT_SAVE_DISABLED');

// One Liner
export const getIsOneLinerEnabled = () => !!isFlagEnabledForUser('frontend_one_liner_enabled');
