import { theme, zIndices } from '../../../reuse';
import { NOTE_LEFT_GUTTER, NOTE_RIGHT_GUTTER } from '../../note/const';

const HEADER_HEIGHT = 20;
const TEXT_MIN_HEIGHT = 48;

export const css = {
  backgroundColor: theme.colors.grey6,
  marginTop: 20,
  paddingBottom: 8,
  paddingLeft: NOTE_LEFT_GUTTER,
  paddingRight: NOTE_RIGHT_GUTTER,
  paddingTop: 8,
  position: 'relative',
  width: '100%',
  '&:hover': {
    backgroundColor: theme.colors.lightBlue1,
  },
} as const;

export const oneLinerHeaderStyle = {
  display: 'flex',
  alignItems: 'center',
  marginBottom: 8,
  position: 'relative',
} as const;

export const betaChipStyle = {
  border: '1px solid',
  borderColor: theme.colors.blurple,
  borderRadius: 12,
  color: theme.colors.blurple,
  fontSize: 12,
  lineHeight: `${HEADER_HEIGHT - 2}px`,
  padding: '0 6px',
} as const;

export const titleContainerStyle = {
  lineHeight: `${HEADER_HEIGHT}px`,
  marginLeft: 9,
} as const;

export const oneLinerTitleStyle = {
  color: theme.colors.grey1,
  fontWeight: 'bold',
} as const;

export const oneLinerTaglineStyle = {
  color: theme.colors.grey3,
  fontSize: 12,
  marginLeft: 8,
  fontStyle: 'italic',
} as const;

const oneLinerTextStyleBase = {
  overflow: 'hidden',
} as const;

export const oneLinerTextStyleOpen = {
  ...oneLinerTextStyleBase,
  height: 'auto',
  minHeight: TEXT_MIN_HEIGHT,
} as const;

export const oneLinerTextStyleClosed = {
  ...oneLinerTextStyleBase,
  height: 0,
} as const;

export const oneLinerCaretButtonStyle = {
  height: 24,
  left: 24,
  padding: 4,
  position: 'absolute',
  top: 6,
} as const;

const oneLinerCaretStyleBase = {
  transition: 'transform .2s ease',
} as const;

export const oneLinerCaretSyleOpen = {
  ...oneLinerCaretStyleBase,
  transform: 'rotate(180deg)',
} as const;

export const oneLinerCaretSyleClosed = oneLinerCaretStyleBase;

export const spacerStyle = {
  flexGrow: 1,
} as const;

export const copiedStyle = {
  bottom: 0,
  left: 0,
  position: 'absolute',
  right: 0,
  top: 0,
  zIndex: zIndices.conditionCopiedMessage,
} as const;

export const copiedBackgroundStyle = {
  backgroundColor: theme.colors.lightBlue2,
  bottom: 0,
  left: 0,
  opacity: 0.6,
  position: 'absolute',
  right: 0,
  top: 0,
} as const;

export const copiedPillStyle = {
  backgroundColor: theme.colors.white,
  borderRadius: theme.other.borderRadius4,
  color: theme.colors.blueRegular,
  fontSize: 12,
  fontWeight: 600,
  height: 20,
  left: '50%',
  padding: '0 20px 0 16px',
  position: 'absolute',
  textAlign: 'center',
  top: '50%',
  transform: 'translateX(-50%) translateY(-50%)',
} as const;

export const copiedMessageStyle = {
  position: 'relative',
  top: -1,
} as const;

export const copiedMessageIconStyle = {
  marginRight: 2,
  position: 'relative',
  top: 3,
} as const;

export const addToNoteStyle = {
  marginRight: 4,
};

export const tooltipStyle = {
  display: 'flex',
};

export const loadingAndErrorStyle = {
  height: 48,
  position: 'relative',
  textAlign: 'center',
} as const;

export const loadingAndErrorConentStyle = {
  left: '50%',
  position: 'absolute',
  top: '50%',
  transform: 'translate(-50%, -50%)',
} as const;

export const loadingSpinnerStyle = {
  display: 'block',
  marginLeft: 'calc(50% - 12px)',
  height: 24,
  width: 24,
};

export const loadingTextStyle = {
  color: theme.colors.grey2,
  marginTop: 3,
} as const;

export const errorTextStyle1 = {
  color: theme.colors.grey3,
  fontWeight: 600,
} as const;

export const errorTextStyle2 = {
  color: theme.colors.grey3,
  fontStyle: 'italic',
} as const;
