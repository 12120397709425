import React from 'react';
import { connect, ConnectedProps } from 'react-redux';

import { ObservationInterpretation } from '~/app/@types';
import { AppState } from '~/app/@types/state';
import { get, interpretationIsCritical } from '~/app/utils';

import { isDismissed } from '~/app/controllers/bulletNoteStatus';
import { sortSuggestionsByTypeAndTags } from '~/app/controllers/suggestions';

import { DismissedComplaint } from './DismissedComplaint';
import '~/app/styles/DismissedList.scss';

const UnconnectedDismissedList: React.FC<PropsFromRedux> = ({
  bulletsByModule,
  currentConditionResults,
  bulletNoteStatusByBulletId,
  indexingData,
}) => {
  const dismissedDxs = currentConditionResults.filter(({ module, negative }) =>
    isDismissed(module, bulletNoteStatusByBulletId, negative)
  );

  return dismissedDxs.length ? (
    <div className="DismissedList">
      <div className="header">({dismissedDxs.length}) Dismissed Dx</div>
      <div className="list-wrapper">
        {dismissedDxs.map((complaint) => {
          const { module, title } = complaint;
          const bulletsToBeDisplayed =
            // 1. get bulletsByTrimmedTextKey
            Object.values(
              get(indexingData.conditionsByModule, module)?.bulletsByTrimmedTextKey ?? {}
            )
              // 2. sort like we do for suggestions
              .sort(sortSuggestionsByTypeAndTags)
              // 3. get rid of everything but the text and isCritical
              .map((bullet) => ({
                text: bullet.text,
                isCritical: interpretationIsCritical(
                  'interpretation' in bullet
                    ? bullet.interpretation
                    : ObservationInterpretation.Unknown
                ),
              }));

          return (
            <DismissedComplaint
              key={module}
              bulletNoteStatusByBulletId={bulletNoteStatusByBulletId}
              bulletsToBeDisplayed={bulletsToBeDisplayed}
              bulletsToBeRestored={get(bulletsByModule, module) ?? []}
              module={module}
              title={title}
            />
          );
        })}
      </div>
    </div>
  ) : null;
};

const connector = connect(
  ({
    regardNote: {
      currentConditionResults,
      bulletsByModule,
      bulletNoteStatusByBulletId,
      indexingData,
    },
  }: AppState) => ({
    bulletsByModule,
    currentConditionResults,
    bulletNoteStatusByBulletId,
    indexingData,
  })
);

type PropsFromRedux = ConnectedProps<typeof connector>;

export const DismissedList = connector(UnconnectedDismissedList);
