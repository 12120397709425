import { BulletType } from '~/app/@types';
import { SimpleCondition, IndexingDataCondition, BulletNoteStatusRecord } from '~/app/@types/state';
import { EXISTING_DIFF, OUTDATED_DIFF } from '~/app/constants';
import { get, convertBulletTextToBulletKey } from '~/app/utils';

// WARNING: Side-effect; this function mutates the conditionsByModule object
export const setupBulletTracking = ({
  conditionNames,
  currentRegardConditionsMap,
  conditionsByModule,
  combinedBulletsInText,
  bulletsAlreadyInText,
}: {
  conditionNames: string[];
  currentRegardConditionsMap: Record<string, SimpleCondition>;
  conditionsByModule: Record<string, IndexingDataCondition>;
  bulletNoteStatusFromAPI: BulletNoteStatusRecord;
  combinedBulletsInText: Record<string, { text: string; conditionTextIndex: number }>;
  bulletsAlreadyInText: Record<string, boolean>;
}) => {
  const bulletNoteStatusUpdates: BulletNoteStatusRecord = {};

  conditionNames.forEach((conditionName) => {
    const currentRegardCondition = currentRegardConditionsMap[conditionName];
    const indexingModuleCondition = conditionsByModule[conditionName];
    if (!currentRegardCondition) return;

    // Here we loop thru all the Regard bullets mapped to this chunk of text. For each one
    // we see if it is in the basenote text
    currentRegardCondition.bullets.forEach(({ diff, id, tags, text, type, ...rest }) => {
      const bulletKey = text.trim().replace(/[ ]{2,}/g, ' ');
      const bulletNoteStatusId = `${conditionName}.${id}`;

      const foundRegardBulletInText = get(bulletsAlreadyInText, bulletKey);
      const foundRegardBulletInCombinedText = get(combinedBulletsInText, bulletKey);
      const foundBullet = !!(foundRegardBulletInText || foundRegardBulletInCombinedText);

      // we only want to track General and OUTDATED_DIFF bullets that are found in the basenote.
      // it would be a performance concern to add all general bullets so we filter out here
      const isIgnoredBullet = type === BulletType.General || diff === OUTDATED_DIFF;

      if (!foundBullet && isIgnoredBullet) return;

      const key = convertBulletTextToBulletKey(text);
      const value = {
        // we mark found (but outdated) bullets as "existing" so Regard will show tracking (grey gutter in UI)
        ...(diff === OUTDATED_DIFF ? { diff: EXISTING_DIFF } : { diff }),
        ids: [bulletNoteStatusId],
        tags,
        text,
        type,
        ...('interpretation' in rest ? { interpretation: rest.interpretation } : {}),
      };

      // add this bullet to indexing data
      indexingModuleCondition.bulletsByTrimmedTextKey[key] = value;
    });
  });

  return {
    bulletNoteStatusUpdates,
  };
};
