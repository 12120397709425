import { MeshedNoteHtmlLine } from '~/app/@types/state';
import {
  get,
  getFirstCharacterStyles,
  convertBulletTextToBulletKey,
  capitalizeFirstLetter,
} from '~/app/utils';
import { addStylesToHtml } from '~/app/utils/addStyles';

const rejectNullLines = (lines: (MeshedNoteHtmlLine | null)[]): MeshedNoteHtmlLine[] =>
  lines.filter((x) => x) as MeshedNoteHtmlLine[];

interface AttemptToMatchRegardBulletHtmlInLinesParams {
  lines: MeshedNoteHtmlLine[];
  matchedConditionNames: string[];
  updatableBulletTextByConditionName: Record<string, Record<string, string>>;
  removableBulletTextKeyToConditionNameMap: Record<string, string[]>;
}

interface AttemptToMatchRegardBulletHtmlInLinesResults {
  bulletsAlreadyInText: Record<string, boolean>;
  bulletUpdates: Record<string, string>;
  combinedBulletsInText: Record<
    string,
    {
      text: string;
      conditionTextIndex: number;
    }
  >;
  linesWithUpdatedBullets: MeshedNoteHtmlLine[];
  removedBulletsFromText: string[];
}

export const attemptToMatchRegardBulletHtmlInLines = ({
  lines,
  matchedConditionNames,
  updatableBulletTextByConditionName,
  removableBulletTextKeyToConditionNameMap,
}: AttemptToMatchRegardBulletHtmlInLinesParams): AttemptToMatchRegardBulletHtmlInLinesResults => {
  // For all the regard modules we found in the base note, iterate over the
  //  bullets which can be updated; remove whitespace and make lowercase

  // NOTE: This processing could probably be done when the
  //  `updatableBulletTextByConditionName` object is first created
  const updatableBulletTextKeyToUpdatedText: Record<string, string> = {};
  matchedConditionNames.forEach((conditionName) => {
    Object.entries(updatableBulletTextByConditionName[conditionName]).forEach(
      ([priorText, replacementText]) => {
        // we generate a better key here by removing all whitespace from the prior text
        // this allows a gentle "fuzzy-match" below just in case an extra space hopped in
        // or out of the note
        updatableBulletTextKeyToUpdatedText[convertBulletTextToBulletKey(priorText)] =
          replacementText;
      }
    );
  });

  const bulletUpdates: Record<string, string> = {};
  const bulletsAlreadyInText: Record<string, boolean> = {};
  const combinedBulletsInText: Record<string, { text: string; conditionTextIndex: number }> = {};
  const removedBulletsFromText: string[] = [];

  // NOTE: We are rejecting the null lines produced by outdated medication
  //  removal.
  const linesWithUpdatedBullets = rejectNullLines(
    lines.map((line) => {
      const mutableLine = line;

      if (mutableLine.type === 'bullet') {
        const { bulletSignifier, plainTextWithoutBulletSignifier } = mutableLine;

        // --- Outdated Medication Removal --- //

        // This is the magic "if" that will remove out-dated Regard med bullets from note text
        const outdatedBulletIdsFound = get(
          removableBulletTextKeyToConditionNameMap,
          convertBulletTextToBulletKey(plainTextWithoutBulletSignifier)
        );
        if (outdatedBulletIdsFound) {
          removedBulletsFromText.push(plainTextWithoutBulletSignifier);
          // by returning here, we never add this bullet text to `conditionText` so it is omitted
          // from the meshedNote
          return null;
        }

        // --- Lab & Vital Bullet Text Updating --- //

        const updatedLineText = get(
          updatableBulletTextKeyToUpdatedText,
          convertBulletTextToBulletKey(plainTextWithoutBulletSignifier)
        );

        if (updatedLineText) {
          // This is the magic "if" that replaces previous-day Regard bullets
          //  found in the progress note with updated bullet text
          bulletUpdates[updatedLineText] = plainTextWithoutBulletSignifier;

          // Retain previous html formatting
          const isFirstCharCapitalized = /^[A-Z]/.test(plainTextWithoutBulletSignifier);
          const capitalizedLineText = isFirstCharCapitalized
            ? capitalizeFirstLetter(updatedLineText)
            : updatedLineText;
          const styles = getFirstCharacterStyles(line.html);

          mutableLine.html = addStylesToHtml(
            `${bulletSignifier} ${capitalizedLineText}` as HtmlString,
            styles
          );
          mutableLine.plainTextWithoutBulletSignifier = capitalizedLineText;
        }

        // --- Outdated-Bullets-Found-in-Base-Note Tracking --- //

        // Regenerate the lineTextKey here to account for updated bullets
        // and OUTDATED or GENERAL bullets found in basenote to track but not
        // update.
        const lineTextKey = (updatedLineText ?? plainTextWithoutBulletSignifier).trim();
        if (lineTextKey) {
          bulletsAlreadyInText[lineTextKey] = true;
        }
      }

      return mutableLine;
    })
  );

  return {
    bulletsAlreadyInText,
    bulletUpdates,
    combinedBulletsInText,
    linesWithUpdatedBullets,
    removedBulletsFromText,
  };
};
