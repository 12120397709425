import { LOGOUT_COMPLETE, REQUEST_LOGOUT, LoginAction } from '../actions/login';
import { LoginState } from '../@types/state';
import { getOAuthSubject, getUserFullName } from '../cookies';

declare global {
  interface Window {
    RNE_BETA_USERS: string[] | undefined;
  }
}

const oauthSubject = getOAuthSubject();
const userName = getUserFullName() ?? '';

const initialState: LoginState = {
  error: null,
  isFetching: false,
  oauthSubject,
  userName,
};

// eslint-disable-next-line default-param-last
function login(state = initialState, action: LoginAction): LoginState {
  switch (action.type) {
    case REQUEST_LOGOUT:
      return { ...state, isFetching: true, error: null };
    case LOGOUT_COMPLETE:
      return {
        oauthSubject: null,
        userName: null,
        isFetching: false,
        error: null,
      };
    default:
      return state;
  }
}

export default login;
