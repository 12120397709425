import {
  ConditionNoteBlock,
  BulletNoteStatusRecord,
  IndexingData,
  PostModuleNoteBlock,
  NoteBlock,
  ShelfDividerBlock,
} from '~/app/@types/state';
import { isConditionNoteBlock, convertBulletTextToBulletKey, replace } from '~/app/utils';

import { updateBulletNoteStatusState } from '../../bulletNoteStatus';
import { getSuggestions } from '../../suggestions';

import {
  RetainStaleNoteBlockDataCase,
  RetainStaleNoteBlockDataResult,
  RetainStaleNoteBlockDataResultHideOrMonitorBullet,
  RetainStaleNoteBlockDataResultSameTitle,
} from '../retainStaleNoteBlockData';
import { preToPostModuleNoteBlocks } from '../preToPostModuleNoteBlocks';
import { transformNoteBlocksLines } from '../transformNoteBlocksLines';

const addSuggestionsToNoteBlocks = <
  T extends Pick<PostModuleNoteBlock, 'bulletsByTrimmedTextKey' | 'type'>
>({
  noteBlocks,
  newBulletNoteStatus,
}: {
  noteBlocks: (T | ShelfDividerBlock)[];
  newBulletNoteStatus: BulletNoteStatusRecord;
}): ((T & Pick<ConditionNoteBlock, 'suggestions'>) | ShelfDividerBlock)[] =>
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore Was not able to figure out how to satisfy the compiler..
  noteBlocks.map((noteBlock) => {
    if (isConditionNoteBlock(noteBlock)) {
      const conditionNoteBlock: T & Pick<ConditionNoteBlock, 'suggestions'> = {
        ...noteBlock,
        suggestions: getSuggestions({
          bulletsByTrimmedTextKey: noteBlock.bulletsByTrimmedTextKey,
          bulletNoteStatus: newBulletNoteStatus,
        }),
      };
      return conditionNoteBlock;
    }

    return noteBlock;
  });

const getNoteBlockWithoutSuggestions = ({
  retainStaleNoteBlockDataResult,
  staleBulletNoteStatus,
}: {
  retainStaleNoteBlockDataResult:
    | RetainStaleNoteBlockDataResultHideOrMonitorBullet
    | RetainStaleNoteBlockDataResultSameTitle;
  staleBulletNoteStatus: BulletNoteStatusRecord;
}): {
  noteBlockWithoutSuggestions: Omit<ConditionNoteBlock, 'suggestions'> | ShelfDividerBlock;
  bulletNoteStatusUpdates: BulletNoteStatusRecord;
} => {
  switch (retainStaleNoteBlockDataResult.retainStaleNoteBlockDataCase) {
    case RetainStaleNoteBlockDataCase.SameTitle: {
      const { editedPostModuleNoteBlock } = retainStaleNoteBlockDataResult;

      // 1. Transform lines on the edited condition area
      const { bulletNoteStatusUpdatesForBullets, noteBlocksWithLines } = transformNoteBlocksLines({
        noteBlocks: [editedPostModuleNoteBlock],
        staleBulletNoteStatus,
      });

      // 2. There are only bulletNoteStatus updates for bullets since no modules have changed
      const bulletNoteStatusUpdates = bulletNoteStatusUpdatesForBullets;

      return {
        noteBlockWithoutSuggestions: noteBlocksWithLines[0],
        bulletNoteStatusUpdates,
      };
    }
    case RetainStaleNoteBlockDataCase.HideOrMonitorBullet:
    default: {
      const { bulletStatus, bulletText, noteBlocksWithOutdatedEditedIndex, editedIndex } =
        retainStaleNoteBlockDataResult;

      // 1. Build the bulletNoteStatus updates for bullets with the new information
      const bulletNoteStatusUpdatesForBullets: BulletNoteStatusRecord = {};
      const noteBlock = noteBlocksWithOutdatedEditedIndex[editedIndex];

      if (isConditionNoteBlock(noteBlock)) {
        const bulletNoteStatusKeys =
          noteBlock.bulletsByTrimmedTextKey[convertBulletTextToBulletKey(bulletText)]?.ids ?? [];

        bulletNoteStatusKeys.forEach((bulletNoteStatusKey) => {
          bulletNoteStatusUpdatesForBullets[bulletNoteStatusKey] = bulletStatus;
        });
      }

      return {
        noteBlockWithoutSuggestions: noteBlock,
        // 2. There are only bulletNoteState updates for bullets since no modules have changed
        bulletNoteStatusUpdates: bulletNoteStatusUpdatesForBullets,
      };
    }
  }
};

export const preModuleNoteBlocksToNoteBlocks = <
  T extends Pick<
    IndexingData,
    | 'allModuleMatchesByTitle'
    | 'conditionsByModule'
    | 'conditionQualifiers'
    | 'conditionKeywords'
    | 'exactMatchRegardTitlesInNoteByModule'
  >
>({
  staleIndexingData,
  retainStaleNoteBlockDataResult,
  staleBulletNoteStatus,
}: {
  staleIndexingData: T;
  retainStaleNoteBlockDataResult: RetainStaleNoteBlockDataResult;
  staleBulletNoteStatus: BulletNoteStatusRecord;
}):
  | {
      // RetainStaleNoteBlockDataCase.NeedsModules
      noteBlocks: NoteBlock[];
      bulletNoteStatusUpdates: BulletNoteStatusRecord;
      newBulletNoteStatus: BulletNoteStatusRecord;
      newIndexingData: T;
    }
  | {
      // RetainStaleNoteBlockDataCase.SameTitle
      noteBlocks: NoteBlock[];
      bulletNoteStatusUpdates: BulletNoteStatusRecord;
      newBulletNoteStatus: BulletNoteStatusRecord;
      newIndexingData: null;
    }
  | {
      // RetainStaleNoteBlockDataCase.Done
      noteBlocks: NoteBlock[];
      bulletNoteStatusUpdates: null;
      newBulletNoteStatus: null;
      newIndexingData: null;
    } => {
  switch (retainStaleNoteBlockDataResult.retainStaleNoteBlockDataCase) {
    case RetainStaleNoteBlockDataCase.NeedsModules:
    case RetainStaleNoteBlockDataCase.NeedsModulesNoCaret: {
      const { preModuleNoteBlocks, editedIndex, movedIntoShelf, movedOutOfShelf } =
        retainStaleNoteBlockDataResult;

      // 1. Add modules to all condition areas
      const {
        allModuleMatchesByTitleUpdates,
        bulletNoteStatusUpdatesForModules,
        postModuleNoteBlocks,
      } = preToPostModuleNoteBlocks({
        preModuleNoteBlocks,
        staleBulletNoteStatus,
        staleIndexingData,
      });

      // 2. Integrate allModuleMatchesByTitleUpdates into staleIndexingData
      const newIndexingData = {
        ...staleIndexingData,
        allModuleMatchesByTitle: {
          ...staleIndexingData.allModuleMatchesByTitle,
          ...allModuleMatchesByTitleUpdates,
        },
      };

      // 3. Transform lines on all condition areas
      const { bulletNoteStatusUpdatesForBullets, noteBlocksWithLines } = transformNoteBlocksLines({
        noteBlocks: postModuleNoteBlocks,
        staleBulletNoteStatus,
        editedIndex,
        movedIntoShelf,
        movedOutOfShelf,
      });

      // 4. Combine bullet and module bulletNoteStatus updates
      const bulletNoteStatusUpdates = {
        ...bulletNoteStatusUpdatesForBullets,
        ...bulletNoteStatusUpdatesForModules,
      };
      const newBulletNoteStatus = updateBulletNoteStatusState(
        bulletNoteStatusUpdates,
        staleBulletNoteStatus
      );

      // 5. Use bulletsByTrimmedTextKey and bulletNoteStatus to calculate suggestions
      const noteBlocksWithSuggestions = addSuggestionsToNoteBlocks({
        noteBlocks: noteBlocksWithLines,
        newBulletNoteStatus,
      });

      return {
        noteBlocks: noteBlocksWithSuggestions,
        bulletNoteStatusUpdates,
        newBulletNoteStatus,
        newIndexingData,
      };
    }
    case RetainStaleNoteBlockDataCase.SameTitle:
    case RetainStaleNoteBlockDataCase.HideOrMonitorBullet: {
      const { noteBlocksWithOutdatedEditedIndex, editedIndex } = retainStaleNoteBlockDataResult;

      const { noteBlockWithoutSuggestions, bulletNoteStatusUpdates } =
        getNoteBlockWithoutSuggestions({
          retainStaleNoteBlockDataResult,
          staleBulletNoteStatus,
        });
      const newBulletNoteStatus = updateBulletNoteStatusState(
        bulletNoteStatusUpdates,
        staleBulletNoteStatus
      );

      // 3. Use bulletsByTrimmedTextKey and bulletNoteStatus to calculate suggestions
      const noteBlocksWithSuggestions = addSuggestionsToNoteBlocks({
        noteBlocks: [noteBlockWithoutSuggestions],
        newBulletNoteStatus,
      });

      const sameTitleNoteBlock = noteBlocksWithSuggestions[0]; // safe assumption

      // 5. Replace the old edited condition area with the new one
      const noteBlocks = replace(
        sameTitleNoteBlock,
        editedIndex,
        noteBlocksWithOutdatedEditedIndex
      );

      return {
        noteBlocks,
        bulletNoteStatusUpdates,
        newBulletNoteStatus,
        newIndexingData: null,
      };
    }
    case RetainStaleNoteBlockDataCase.Done:
    default: {
      // 1. Return the `noteBlocks` from the previous step; there are no other
      //  updates

      return {
        noteBlocks: retainStaleNoteBlockDataResult.noteBlocks,
        bulletNoteStatusUpdates: null,
        newBulletNoteStatus: null,
        newIndexingData: null,
      };
    }
  }
};
