import { SummaryP, SummarySpanB, SummaryUl } from './common';

export const obstructiveLungSummary = (
  <>
    <SummaryP first>
      First Regard will check for a chronic history of COPD or asthma. If associated home
      medications are found <SummarySpanB>and</SummarySpanB> a condition from the following list is
      present, Regard will trigger a <SummarySpanB>positive</SummarySpanB> Dx.
    </SummaryP>
    <SummaryUl>
      <li>Chronic bronchitis</li>
      <li>Emphysema</li>
      <li>Unspecified COPD</li>
      <li>Asthma</li>
      <li>Bronchiectasis</li>
    </SummaryUl>
    <SummaryP>
      If diagnosed for an obstructive lung disease, the program will check for exacerbation based on
      patients notes from the last 2 weeks, SpO2 &lt; 88%, and a RR &gt; 20. If current exacerbation
      in encounter, Regard will display exacerbation details.
    </SummaryP>
    <SummaryP>
      If diagnosed for an obstructive lung disease, display medications (active, held, home), latest
      supplementary oxygen data, and retrieve latest lab panel results: ABG, VBG, and respiratory
      culture.
    </SummaryP>
  </>
);
