import { addStylesToHtml } from '~/app/utils/addStyles';
import { BulletType } from '~/app/@types';
import {
  IndexingData,
  SimpleCondition,
  Bullet,
  Stats,
  BulletIndexingMetaData,
  BulletNoteStatusRecord,
  IndexingDataCondition,
  TitleStyles,
} from '~/app/@types/state';
import { convertBulletTextToBulletKey, uniqueId } from '~/app/utils';
import { EXISTING_DIFF, NEW_DIFF, OUTDATED_DIFF } from '~/app/constants';
import { isHidden } from '../bulletNoteStatus';

const generateIndexingDataCondition = ({
  bullets,
  suggestedQualifier,
  module,
  negative,
}: {
  bullets: Bullet[];
  suggestedQualifier: string;
  module: string;
  negative: boolean;
}): IndexingDataCondition => {
  const bulletsByTrimmedTextKey: Record<string, BulletIndexingMetaData> = {};
  bullets.forEach(({ id, tags, text, type, ...rest }) => {
    const key = convertBulletTextToBulletKey(text);
    const value = {
      // in problem-based-charting mode we want to diff ALL condition bullets as normal
      // in the default application we want all bullets of NEW conditions to also be NEW
      diff: NEW_DIFF,
      ids: [`${module}.${id}`],
      tags,
      text,
      type,
      ...('interpretation' in rest ? { interpretation: rest.interpretation } : {}),
    };

    bulletsByTrimmedTextKey[key] = value;
  });

  return {
    id: uniqueId(),
    modules: [module],
    // isRegardTitle: true, // not being used; Indicates that this condition was added by Regard
    diff: negative ? EXISTING_DIFF : NEW_DIFF, // Indicates the Regard diff state of the entire condition
    bulletsByTrimmedTextKey,
    suggestedQualifier,
  };
};

const getConditionToTitleTextFunctionAndMutateIndexData =
  ({
    currentRegardConditionsMap,
    bulletNoteStatusFromAPI,
    startingBulletNumber,
    titleSignifier,
    titleStyles,
    indexingData,
    stats,
  }: {
    currentRegardConditions: SimpleCondition[];
    currentRegardConditionsMap: Record<string, SimpleCondition>;
    bulletNoteStatusFromAPI: BulletNoteStatusRecord;
    startingBulletNumber: number;
    titleSignifier: string;
    titleStyles: TitleStyles;
    indexingData: IndexingData;
    stats: Stats;
  }) =>
  ({ bullets, module, title, negative }: SimpleCondition, bulletOffset: number) => {
    // filter out General and OUTDATED_DIFF bullets from indexing data
    // it would be a performance concern to add all general bullets
    const assessmentAndPlanBullets = bullets.filter(
      ({ type, diff }) => type !== BulletType.General && diff !== OUTDATED_DIFF
    );

    // Prepare index object for condition
    // eslint-disable-next-line no-param-reassign
    indexingData.conditionsByModule[module] = generateIndexingDataCondition({
      ...currentRegardConditionsMap[module],
      bullets: assessmentAndPlanBullets,
      module,
    });

    // Don't add the condition title text if dismissed or negative
    if (isHidden(module, bulletNoteStatusFromAPI) || negative) {
      return '';
    }

    stats.newConditions.push(module);
    const bulletForTitle = titleSignifier
      ? titleSignifier.replace('1', `${startingBulletNumber + bulletOffset}`)
      : '#';

    const titleText = `${bulletForTitle} ${title}` as HtmlString;
    return addStylesToHtml(titleText, titleStyles);
  };

// Here we iterate through all Regard DX Props that were _not_ matched
// to a title in the previous progress note.
export const appendNewRegardDxes = ({
  currentRegardConditions,
  currentRegardConditionsMap,
  bulletNoteStatusFromAPI,
  startingBulletNumber,
  titleSignifier,
  titleStyles,
  indexingData,
  stats,
}: {
  currentRegardConditions: SimpleCondition[];
  currentRegardConditionsMap: Record<string, SimpleCondition>;
  bulletNoteStatusFromAPI: BulletNoteStatusRecord;
  startingBulletNumber: number;
  titleSignifier: string;
  titleStyles: TitleStyles;
  indexingData: IndexingData;
  stats: Stats;
}): HtmlString[] => {
  const positiveRegardConditionsThatDontAppearInTheNote = currentRegardConditions.filter(
    ({ module, negative }) => !stats.matchedConditions.includes(module) && !negative
  );

  // Add all positive condition titles to indexingData so if user creates a new title for this condition
  // above in the note, we can remove this Regard condition. The user would consider this a duplicate
  positiveRegardConditionsThatDontAppearInTheNote.forEach(({ title, module }) => {
    // eslint-disable-next-line no-param-reassign
    indexingData.exactMatchRegardTitlesInNoteByModule[module] = title;
  });

  // Add all negative conditions to the indexingData
  currentRegardConditions
    .filter(({ negative }) => negative)
    .forEach(
      getConditionToTitleTextFunctionAndMutateIndexData({
        currentRegardConditions,
        currentRegardConditionsMap,
        bulletNoteStatusFromAPI,
        startingBulletNumber,
        titleSignifier,
        titleStyles,
        indexingData,
        stats,
      })
    );

  return positiveRegardConditionsThatDontAppearInTheNote
    .map(
      // Add all positive conditions that don't appear in the note (yet but will be added) to the indexingData
      getConditionToTitleTextFunctionAndMutateIndexData({
        currentRegardConditions,
        currentRegardConditionsMap,
        bulletNoteStatusFromAPI,
        startingBulletNumber,
        titleSignifier,
        titleStyles,
        indexingData,
        stats,
      })
    )
    .filter((chunk) => chunk) as HtmlString[];
};
